import * as React from "react";
import Title from "../elements/Title";

const ReleaseNotesSection = ({ title, description, children }) => (
  <div className="bg-white">
    <Title title={title} description={description} />
    <div className="grid max-w-5xl mx-auto pt-4 px-4 sm:px-6">{children}</div>
  </div>
);

export default ReleaseNotesSection;
