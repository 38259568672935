import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { components } from "../sections/SingleArticleSection";

const Ul = (props) => (
  <ul className="sm:ml-8 mb-4 list-inside list-disc text-gray-600" {...props} />
);
const Li = (props) => <li className="mb-4" {...props} />;

const styledComponents = { ul: Ul, li: Li };

const ReleaseNoteItem = ({ articleTitle }) => {
  const [markdown, setMarkdown] = useState({});

  const data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          body
          frontmatter {
            title
          }
        }
      }
    }
  `);

  useEffect(() => {
    setMarkdown(
      data.allMdx.nodes.find((e) => e.frontmatter.title.includes(articleTitle))
    );
  }, [data, articleTitle]);

  return (
    <div key={articleTitle} className="bg-white sm:px-6 py-6 text-gray-500 text-lg border-t border-gray-200">
      <h2 className="block leading-8 font-extrabold tracking-tight text-xl text-left text-gray-900 sm:text-2xl">
        {articleTitle}
      </h2>
      <div className="mt-4">
        {markdown.body && (
          <MDXProvider components={{ ...components, ...styledComponents }}>
            <MDXRenderer>
              {/* Content of article */}
              {markdown.body}
            </MDXRenderer>
          </MDXProvider>
        )}
      </div>
    </div>
  );
};

export default ReleaseNoteItem;
