import * as React from "react";
import PageTitle from "../../components/elements/PageTitle";
import ReleaseNoteItem from "../../components/elements/ReleaseNoteItem";
import Footer from "../../components/sections/Footer";
import Header from "../../components/sections/Header";
import ReleaseNotesSection from "../../components/sections/ReleaseNotesSection";

const ReleaseNotesPage = () => (
  <div className="bg-white">
    <PageTitle
      pageTitle={"Release notes"}
      description={``}
    />
    <Header />
    <ReleaseNotesSection title="Release notes" description="">
      <ReleaseNoteItem articleTitle={"v40"} />
      <ReleaseNoteItem articleTitle={"v39"} />
    </ReleaseNotesSection>
    <Footer />
  </div>
);
export default ReleaseNotesPage;
